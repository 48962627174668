@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.cards {
  padding: 4rem;
  background: #fff;
}

#diskon h1 {
  font-family: 'Archivo', sans-serif;
  font-size: 40px;
  font-weight: 700;
  text-align: center;

  @media screen and (max-width: 576px) {
    font-size: 28px;
  }
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0px auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
}

.cards__item__img {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

.Tautan {
  color: #f5bb34;
  justify-content: right;
}

.Tautan a {
  font-size: 1rem;
  margin-left: 2rem;
  text-decoration: none;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .cards {
    padding: 0;
  }
}

/* Banner diskon agt */

.banner-diskonagt {
  background-image: url('/public/images/Diskon/bgdiskonbanner.png');
  background-position: center;
  background-size: cover;
  height: 250px;
  font-family: 'Poppins', sans-serif;

  @media only screen and (max-width: 1023px) {
    max-width: 479.61px;
    /* background-size: contain; */
    background-position: left;
  }
  @media only screen and (max-width: 576px) {
    max-width: none;
    width: 89%;
    height: 100%;

    /* background-size: contain; */
  }
}

.banner-diskonagt-left {
  padding-top: 20px;
  border-bottom: 5px dotted rgba(255, 255, 255, 1);
  height: 100%;
  width: 24rem;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  color: white;

  @media only screen and (max-width: 1023px) {
    /* height: 8rem; */
    /* width: rem; */
    padding-top: 120px;
    /* margin-top: -120px; */
    margin-left: -120px;
  }
}

.banner-diskonagt-left p {
  line-height: 16px;
  font-size: 20px;
  margin-bottom: 20px;
  @media only screen and (max-width: 1023px) {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.banner-diskonagt-left h1 {
  font-weight: bold;
  font-size: 5.5rem;
  @media only screen and (max-width: 1023px) {
    font-size: 3rem;
  }
}

.banner-diskonagt-right {
  margin-left: -32px;
  @media only screen and (max-width: 1023px) {
    margin-left: 0px;
  }
}

.banner-diskonagt-right .banner-diskon-agt-masukan {
  font-size: 24px;
  @media only screen and (max-width: 1023px) {
    font-size: 20px;
  }
}
.banner-diskonagt-right h1 {
  /* font-size: 5rem;
  font-weight: bold; */
  letter-spacing: 4px;
  @media only screen and (max-width: 1023px) {
    /* margin-left: -64px; */
    margin-top: -20px;
    font-size: 3rem;
  }
  @media only screen and (max-width: 694px) {
    /* margin-left: -60px; */
    /* background-size: contain; */
  }
}

.banner-diskonagt-goagt3 {
  font-size: 5rem;
  font-weight: bold;
  letter-spacing: 4px;
  margin-top: -28px;
  margin-bottom: -12px;
  @media only screen and (max-width: 1023px) {
    /* margin-left: -64px; */
    margin-top: -20px;
    font-size: 3rem;
  }
}
