.featured {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.header-fasilitas {
  font-family: 'Archivo', sans-serif;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  @media screen and (max-width: 576px) {
    font-size: 28px;
  }
}

.featured .featured-text {
  text-align: center;
  margin: 1rem 0;
}

.featured .container {
  --gap: 0.7rem;
  --num-cols: 5;

  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  gap: var(--gap);
}

.featured .container img {
  width: 100%;
  height: 100%;
}

.span-3 {
  grid-column: span 2;
}
.span-2 {
  grid-column: span 1;
}

.image-grid-row-2 {
  grid-row: span 2;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(2, 0.2fr);
}

.right-img-details .btn {
  margin: 1rem 0;
}

.featured .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.featured .top .price {
  font-weight: 600;
  color: #008000;
  font-size: 1.5rem;
}

.featured .info {
  display: flex;
  justify-content: space-between;
  margin: 0.8rem 0;
  width: 60%;
}

.featured .bold {
  font-weight: 600;
  margin-right: 1rem;
}

.featuredbutton {
  width: 250px;
  margin: auto;
  padding: 20px 20px;
  font-weight: 700;
  font-size: 0.688rem;
  line-height: 18px;
  letter-spacing: 1.54px;
  text-transform: uppercase;
  border-radius: 25px;
  border: none;
  background-color: #ec2b39;
  color: white;
  cursor: pointer;
  transition: all 0.4s ease-in;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .featured .container {
    --num-cols: 2;
    --grap: 0.5rem;
  }

  .span-3 {
    grid-column: span 2;
  }

  .order-1 {
    order: 1;
  }
  .order-2 {
    order: 2;
  }
  .order-3 {
    order: 3;
  }
  .order-4 {
    order: 4;
  }
  .order-5 {
    order: 5;
  }
  .order-6 {
    order: 6;
  }
  .order-7 {
    order: 7;
  }
}
