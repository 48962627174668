.container-programsgo {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-decoration: none !important;
  margin: auto;

  /* width: 100%; */
}

.wrapper-programsgo {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 32px;
  max-width: 1400px;
  margin: auto;
}

.img-programsgo {
  background-color: aqua;
}

.cardprogramgo {
  width: 24em;
  height: 30em;
  /* height: 100%; */
  background-color: white;
  border-radius: 5%;
  padding: 0.75em;
  box-shadow: 0px 2px 25px -2px rgba(0, 0, 0, 0.48);
  -webkit-box-shadow: 0px 2px 25px -2px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: 0px 2px 25px -2px rgba(0, 0, 0, 0.48);
  @media screen and (max-width: 576px) {
    width: 310px;
    height: 100%;
  }
}

.substituteprogramgo {
  margin: 0px auto;
  width: 22em;
  height: 15em;

  box-shadow: 0px 2px 35px -3px rgba(184, 184, 184, 0.48);
  -webkit-box-shadow: 0px 2px 35px -3px rgba(184, 184, 184, 0.48);
  -moz-box-shadow: 0px 2px 35px -3px rgba(184, 184, 184, 0.48);
  background-color: #fff8ed;
  border-radius: 5%;
  @media screen and (max-width: 576px) {
    width: 95%;
    height: fit-content;
  }
}

.cardprogramgo p {
  text-align: center;
  color: black;
  margin-left: 0.5em;
  margin-right: 0.5em;
  font-size: 15px;
  margin-top: 8px;
  text-decoration: none;
}

.cardprogramgo p .bold-text {
  color: red;
  font-size: 1.2rem;
  font-weight: 700;
}

.imgcar {
  width: 20%;
}
