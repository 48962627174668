.container-carousel {
  padding: 40px;
  background: #fff;
}

.slick-prev:before,
.slick-next:before {
  /* display: none; */
  color: whitesmoke !important;
}

.card-test-container {
  padding: 64px 64px;

  @media screen and (max-width: 600px) {
    padding: 34px 12px 84px 12px;
  }
}
.card-testi-yt {
  border: 1px solid red;
  margin-right: 12px;
  cursor: pointer;
  height: 400px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-top: 10px;
  border-radius: 24px;
  transition-duration: 800ms;
  /* transition: all; */
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);

  @media screen and (max-width: 950px) {
    background-size: cover;
    background-position: top;
  }
  @media screen and (max-width: 450px) {
    background-size: cover;
    background-position: top;
  }
}

.card-testi-yt:hover {
  border: 2px solid red;
  height: 450px;
  transition: all;
  width: 110%;
  transition-duration: 600ms;
  @media screen and (max-width: 450px) {
    width: 95%;
    height: 420px;
  }
}

.card-testi-yt > .card-test-diterima {
  background-color: white;
  color: #242424;
  border: 1px solid #ffd0d9;
  vertical-align: middle;
  border-radius: 8px;
  min-width: 250px;
  /* width: 250px; */
  /* height: 55px; */
  padding: 4px;
  height: min-content;
  /* line-height: 12px; */
  text-align: center;
  left: -12px;
  top: -12px;
  position: absolute;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  @media screen and (max-width: 340px) {
    top: 0;
    font-size: 12px;
  }
}
.card-test-diterima > p {
  margin-top: 8px;
  font-size: smaller;
  line-height: 2px;
}

.card-test-diterima > img {
  margin-right: 4px;
}

.card-testi-texttestimoni {
  position: absolute;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
  background-color: #ec323f;
  /* max-width: 400px; */
  border-radius: 8px;
  padding: 8px;

  line-height: 28px;
  letter-spacing: 2px;
  font-size: 14px;
  line-height: 16px;
  bottom: -64px;
  font-weight: 200;
  right: -20px;
  box-shadow: 0px 7px 15px -3px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  /* @media screen and (min-width: 450px) {
    right: 80px;
    margin-left: 0px;
  } */
  @media screen and (max-width: 450px) {
    font-size: 16px;
    bottom: -94px;
  }
}

.testimonigo {
  font-family: 'Archivo', sans-serif;
  font-size: 40px;
  font-weight: 700;

  color: #1c2237;
  /* opacity: 0; */
  padding-top: 30px;
  margin-top: 20px;

  @media screen and (max-width: 576px) {
    font-size: 28px;
  }
}
