.imgprogramgo {
  width: 75%;
  display: flex;
  @media screen and (max-width: 976px) {
    width: 40%;
    max-width: 648px;
  }
  @media screen and (max-width: 575px) {
    width: 65%;
  }
}

.ulprogramgo{
  @media screen and (max-width: 1000px) {
    font-size: 12px;
  }
}

.descriptionprogramgo{
  @media screen and (max-width: 575px) {
    font-size: 12px;
  }
}
