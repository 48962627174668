.modaltestiyt-container {
  z-index: 1000;
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  position: fixed;
}

.modaltestiyt-bg {
  position: fixed;

  background-color: black;
  background: rgb(34, 34, 34);
  height: 100vh;
  width: 100vw;
  z-index: 999;
  opacity: 0.4;
}

.modaltestiyt-wrapper {
  font-family: 'Rubik', sans-serif;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
 
  color: white;
  height: 450px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.25);

  @media screen and (min-width: 768px) {
    height: 400px;
  }
  @media screen and (max-width: 500px) {
    height: fit-content;
  }
}

.iframe-testi {
  width: 100%;
  height: 450px;
}
