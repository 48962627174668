.herogokre .containergokre {
  display: grid;
  grid-template-columns: repeat(3, 0.5fr);
  margin-top: 80px;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(to left, #ec2b39 100%, #eee 5%);
  font-family: 'Poppins', sans-serif;
  height: 400px;
}

.herogokre .gokreleft {
  padding: 1rem;
  @media screen and (max-width: 945px) {
    margin-top: 8em;
  }
}

.judul {
  margin-left: 5rem;
  margin-bottom: 0px;
}

.herogokre h1 {
  padding: 1rem 0;
  text-align: left;
}

.herogokre .input-container {
  padding: 1rem 0;
}

.herogokre .gokreright {
  padding: 1rem;
  grid-column: 2 / 4;
  color: white;
  position: relative;
  bottom: 130px;
}

.AppIcon {
  margin-right: 0.2rem;
  width: 200px;
}

.herogokre .img-gokre {
  display: flex;
  position: relative;
  bottom: 130px;
}

.herogokre .img-download {
  width: 100%;
  margin-top: 20px;
  gap: 0;
}

.herogokre img {
  max-width: 80%;
  margin: auto;
}

@media screen and (max-width: 945px) {
  .herogokre .containergokre {
    margin: -1px 0px;
    height: fit-content;
  }
}

@media screen and (max-width: 945px) {
  .herogokre .containergokre {
    display: flex;
    flex-direction: column;
  }

  .herogokre .gokreright {
    padding: 0;
    margin-top: 0;
    width: 100%;
    height: 100%;
  }
}
