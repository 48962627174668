.quotes {
  display: none;
}


.marquee-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.marquee-text {
  display: inline-block;
  white-space: nowrap; /* Ensure text stays on a single line */
}
