.hero3b .containerheader3b {
  display: grid;
  grid-template-columns: repeat(12, 0.2fr);
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(to left, #ec2b39 100%, #eee 5%);
  font-family: 'Poppins', sans-serif;
  height: fit-content;
  padding-top: 5rem;
}

.hero3b .left {
  padding: 1rem;
}

.judul {
  margin-left: 1rem;
  margin-bottom: 0px;
}

.hero3b h1 {
  padding: 1rem 0;
  text-align: left;
}

.hero3b .input-container {
  padding: 1rem 0;
}

.hero3b .right {
  padding: 1rem;
  grid-column: 2 / 10;
  color: white;
  /* margin-top: 140px; */
}

.AppIcon {
  margin-right: 0.2rem;
  width: 200px;
}

.hero3b .img-header3b {
  /* display: flex; */
  width: 350px;
}

.hero3b .img-download {
  width: 100%;
}

.hero3b img {
  max-width: 70%;
  margin: auto;
}

@media screen and (max-width: 1320px) {
  .hero3b .img-header3b {
    display: none;
  }
}
@media screen and (max-width: 945px) {
  .hero3b .containerheader3b {
    /* height:300px; */
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
  }

  .hero3b .img-header3b {
    display: none;
  }

  .hero3b .right3b {
    padding: 1rem;
    margin-top: 100px;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 680px) {
  .hero3b .containerheader3b {
    height: 400px;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 450px) {
  .hero3b .containerheader3b {
    height: 350px;
    padding: 1rem;
  }
  .hero3b h1 {
    font-size: 25px;
  }

  .hero3b .right3b {
    margin-top: 200px;
  }
}
