@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.diskonagt-bg {
  position: fixed;

  background-color: black;
  background: rgb(34, 34, 34);
  height: 100vh;
  width: 100vw;
  z-index: 999;
  opacity: 0.8;
  /*? if aler add margin top */
  margin-top: 50px;
}

.diskonagt-bg-noal {
  position: fixed;

  background-color: black;
  background: rgb(34, 34, 34);
  height: 100vh;
  width: 100vw;
  z-index: 999;
  opacity: 0.8;
  /*? if aler add margin top */
  /* margin-top: -50px; */
}

@media screen and (min-width: 825px) {
  .diskonagt-bg {
    position: fixed;

    background-color: black;
    background: rgb(34, 34, 34);
    height: 100vh;
    width: 100vw;
    z-index: 999;
    opacity: 0.8;
    /*? if aler add margin top */
    margin-top: -50px;
  }

.diskonagt-bg-noal {
position: fixed;

background-color: black;
background: rgb(34, 34, 34);
height: 100vh;
width: 100vw;
z-index: 999;
opacity: 0.8;
/*? if aler add margin top */
/* margin-top: -50px; */
}

}

@media screen and (min-width: 1535px) {
  .diskonagt-bg {
    position: fixed;

    background-color: black;
    background: rgb(34, 34, 34);
    height: 100vh;
    width: 100vw;
    z-index: 999;
    opacity: 0.8;
    /*? if aler add margin top */
    margin-top: -43px;
  }

.diskonagt-bg-noal {
position: fixed;

background-color: black;
background: rgb(34, 34, 34);
height: 100vh;
width: 100vw;
z-index: 999;
opacity: 0.8;
/*? if aler add margin top */
/* margin-top: -43px; */
}

}

.diskonagt-container {
  z-index: 1000;
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  position: fixed;
}

.diskonagt-wrapper {
  font-family: 'Poppins', sans-serif;
  position: relative;
  height: 560px;
  width: 600px;
  border-radius: 8px;

  /* background-color: red; */
  /* overflow: hidden; */
  /* border: 3px solid rgba(200, 40, 59, 0.5);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 5px 5px rgba(0, 0, 0, 0.25); */

  @media screen and (max-width: 768px) {
    width: 80%;
    /* height: 520px; */
  }

  @media screen and (max-width: 400px) {
    margin-top: 50vh;
    /* height: 520px; */
  }
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .diskonagt-wrapper {
    width: 80%;
  }
}

#imgsliderok {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 5px 5px rgba(0, 0, 0, 0.25);
}

.diskonagt-top {
  background-image: url('/public/images/Diskon/bgdiskonpopup.png');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  border-radius: 4px 8px 0px 0px;

  width: 100%;
  height: 200px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    height: 150px;
  }

  @media screen and (max-width: 425px) {
    height: 120px;
    padding-top: 12px;
  }

  @media screen and (max-width: 320px) {
    height: 110px;
    padding-top: 14px;
  }
}

.diskonagt-top p {
  font-size: 1.5rem;
  font-weight: 500;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
}

.diskonagt-top h1 {
  margin-top: -20px;
  font-size: 4rem;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    margin-top: -10px;
    font-size: 3rem;
  }
}

.diskonagt-bottom {
  padding: 0px 16px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 426px) {
    margin-top: 2px;
  }
}

.diskonagt-bottom button {
  /* writing-mode: vertical-lr;
  text-orientation: sideways; */
  font-size: 20px;
  width: 250px;

  /* background-color: yellow; */
  @media screen and (max-width: 768px) {
    width: 200px;
    font-size: 16px;
  }

  @media screen and (max-width: 426px) {
    width: 150px;
    font-size: 16px;
  }

  @media screen and (max-width: 350px) {
    margin-top: -10px;
  }
}

.diskonagt-bottom p {
  font-size: 16px;

  /* @media screen and (max-width: 768px) {
    font-size: 14px;
  } */
  @media screen and (max-width: 426px) {
    margin-top: 8px;
    /* width: 150px; */
    font-size: 14px;
    line-height: 19px;
  }

  @media screen and (max-width: 320px) {
    font-size: 12px;
  }
}

.diskonagt-bottom .diskonagt-countdown {
  font-size: 20px;

  @media screen and (max-width: 426px) {
    margin-top: -10px;
    font-size: 16px;
    margin-bottom: 8px;
  }
}

.btn-success-copied {
  background-color: #22c55e;
  color: white;
}

.img-popup-diskon {
  height: auto;
  width: 90%;
}