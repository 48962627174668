.hero .container {
  display: grid;
  grid-template-columns: repeat(3, 0.5fr);
  justify-content: center;
  align-items: self-start;
  padding: 1rem;
  max-height: 2000px;
  margin-bottom: auto;
}

.hero .left {
  padding: 1rem;
}

.judul {
  margin-left: 5rem;
  margin-bottom: 0px;
}

.hero h1 {
  padding: 1rem 0;
  text-align: left;
}

.hero .input-container {
  padding: 1rem 0;
}

.hero .right {
  /* padding: 1rem; */
  grid-column: 2 / 4;
}

.hero .img-container {
  display: flex;
}

.hero img {
  max-width: 90%;
  margin: auto;
  z-index: 5;
}

/* @media screen and (max-width: 945px) {
  .hero .container {
    grid-template-columns: 0.5fr;
    margin: 1rem 0;
    height: 1200px;
  }
} */

@media screen and (max-width: 945px) {
  .hero .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hero .right {
    padding: 0;
    margin-top: 0;
    width: 100%;
    height: 100%;
  }
}
